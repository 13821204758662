import './App.css';
import { Flex } from '@chakra-ui/react'
import ParticlesBackground from "./ParticlesBackground"
import Elon from './assets/AdAstraElon.png'
import Buy from './assets/Buy.png'

function BuyToken() {
  const url = 'https://app.uniswap.org/swap?outputCurrency=0x161A95AE00e89E047DBB9B02a8e3085122a64BCd&chain=ethereum'
  window.open(url, '_blank')
}

function App() {
  return (
    <div className="overlay">
      <ParticlesBackground />
        <div className="App">
            <Flex justifyContent="center" alignItems="center" paddingTop="300px">
              <img src={Elon} alt=''/>
            </Flex>
            <Flex justifyContent="center" alignItems="center" paddingTop="50px">
              <img src={Buy} onClick={BuyToken} alt=''/>
            </Flex>
        </div>
    </div>
  );
}

export default App;