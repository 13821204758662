
import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const ParticlesBackground = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        image: "url('https://raw.githubusercontent.com/AdAstraERC20/Website/main/assets/darkstar.jpg')"
      },
      fpsLimit: 120,
      particles: {
        move: {
          direction: "top",
          enable: true,
          outModes: {
            default: "out",
          },
          speed: 5,
          straight: true,
          angle: 0,
        },
        number: {
          value: 17,
        },
        shape: {
          type: "images",
          options: {
            images: [
              {
                src: "https://raw.githubusercontent.com/AdAstraERC20/Website/main/assets/rocket_emoji.png",
                width: 155, 
                height: 328
              },
            ]
          }
        },
        size: {
          value: 10,
        },
      },      
      }),
    [],
  );

  if (init) {
    return (
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />
    );
  }

  return <></>;
};

export default ParticlesBackground;